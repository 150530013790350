import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { msgData, getAlarmsType } from "../api/charts";
import vueSeamlessScroll from "vue-seamless-scroll";
import { color } from "echarts/lib/export";
import filterDialog from "./filterDialog.vue";
export default {
  components: {
    vueSeamlessScroll,
    filterDialog
  },
  props: {
    tableData: {
      type: Array,
      // 或者 Object，具体取决于 `tableData` 的类型
      required: true // 如果这个属性是必须的
    },

    dataUpdateFlag: {
      //这个参数标明是否是编辑后需要更新的数据
    }
  },
  name: "DynamicTable",
  data() {
    return {
      tableDataShow: [],
      //全量数据
      scrollInterval: null,
      scrollStep: 1,
      scrollHeight: 5,
      updateFlag: true,
      showfilterDialog: false,
      filterData: [],
      //筛选后要显示的数据
      formData: {},
      alarmsType: [],
      //获得到的报警信息类型
      uniqueData: [] //经过id去重后的数组
    };
  },

  mounted() {
    this.$nextTick(() => {
      // this.startScrolling();
    });
    setTimeout(() => {
      // listData length无变化，仅仅是属性变更，手动调用下组件内部的reset方法
      this.$refs.seamlessScroll.reset();
    }, 200);
  },
  methods: {
    formatTimestamp(cellValue) {
      // console.log(cellValue, "cellValue");
      if (!cellValue) return "";
      const date = new Date(cellValue * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    openFilter() {
      //打开筛选对话框
      //  this.showfilterDialog = true;
      this.showfilterDialog = !this.showfilterDialog;
      getAlarmsType().then(res => {
        console.log(res, "res");
        this.alarmsType = res.data;
        console.log(this.alarmsType, "res.data=>this.alarmsType @@@@@@@@@@@@@@");
      });
    },
    handleSubmit(formData) {
      console.log("Form Submitted:", formData);
      // 处理表单提交的数据
      this.formData = JSON.parse(JSON.stringify(formData));
      this.filterData = this.uniqueData.filter(item => formData.checkedAlarms.includes(item.typeText));
      console.log(this.filterData, this.filterData);
    },
    getRestult() {
      this.tableDataShow = [{
        id: 53,
        deviceId: 5,
        name: "光伏系统2",
        type: 2,
        typeText: "警告",
        content: "背板温度高高高高高高高高高高高高高",
        createTime: 1717557861
      }, {
        id: 52,
        deviceId: 5,
        name: "光伏系统3",
        type: 2,
        typeText: "警告",
        content: "背板温度高",
        createTime: 1717556309
      }, {
        id: 51,
        deviceId: 5,
        name: "光伏系统1",
        type: 1,
        typeText: "一般",
        content: "背板温度高",
        createTime: 1717556066
      }, {
        id: 50,
        deviceId: 5,
        name: "光伏系统1",
        type: 3,
        typeText: "严重",
        content: "背板温度过高",
        createTime: 1717555994
      }];
    },
    startScrolling() {
      const tableBody = this.$refs.tableBody;
      console.log(tableBody.scrollHeight, "scrollHeight");
      console.log(tableBody.scrollTop, "scrollTop");
      console.log(tableBody.scrollStep, "scrollStep");
      console.log(tableBody.clientHeight, "clientHeight");
      if (tableBody) {
        this.scrollInterval = setInterval(() => {
          tableBody.scrollTop += this.scrollStep;
          console.log(tableBody.scrollTop, "scrollTop");
          // 当滚动到接近底部时，回到顶部
          // if (
          //   tableBody.scrollTop + tableBody.clientHeight >=
          //   tableBody.scrollHeight
          // ) {
          //   tableBody.scrollTop = 0; // 回到顶部
          // }
        }, 100); // 控制滚动速度
      }
    },

    setFontColor(type) {
      switch (type) {
        case 3:
          return "red";
        case 2:
          return "orange";
        case 1:
          return "#facf12";
        //绿色
        default:
          return "#a3a3a3";
        // 默认颜色灰色
      }
    },

    clickAlarmEvent(param) {
      console.log("单击了表单行事件，参数", param);
      this.$emit("clickTableItem", param);
    },
    removeDuplicates() {
      //去重
      this.uniqueData = Object.values(this.tableDataShow.reduce((acc, current) => {
        acc[current.id] = current;
        return acc;
      }, {}));
      console.log(this.uniqueData, "uniqueData");
    },
    //将符合用户筛选的数据过滤出来到filterData中
    filterDataTo() {
      console.log(this.formData, "this.formData");
      if (Object.keys(this.formData).length != 0) {
        console.log(this.formData, "this.formData");
        this.filterData = this.uniqueData.filter(item => this.formData.checkedAlarms.includes(item.typeText));
      } else {
        this.filterData = JSON.parse(JSON.stringify(this.tableDataShow));
      }
      console.log(this.tableDataShow, "this.tableDataShow");
    }
  },
  beforeDestroy() {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
    }
  },
  computed: {
    classOption() {
      return {
        step: 0.5,
        // 数值越大速度滚动越快
        limitMoveNum: 3,
        // 开始无缝滚动的数据量
        hoverStop: true,
        // 是否开启鼠标悬停stop
        direction: 1,
        // 0向下 1向上 2向左 3向右
        openWatch: true,
        // 开启数据实时监控刷新dom
        singleHeight: 0,
        // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0,
        // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 500 // 单步运动停止的时间(默认值1000ms)
      };
    }
  },

  watch: {
    tableData() {
      // this.tableDataShow = [...this.tableData];
      // // //this.getRestult(); //启用模拟假数据
      // this.tableDataShow.push({});

      if (this.tableData.length == 0) {
        //tableData为空是通常是websocket中断了，此时重置tableDataShow
        this.tableDataShow = [];
        console.log("tableData为空，重置 this.tableDataShow ");
      }
      //如果更新数据标志位为true就执行push，否则
      if (this.dataUpdateFlag) {
        this.tableDataShow = [...this.tableData];
        // this.tableDataShow.push({});
      } else {
        this.tableDataShow.push({});
        this.tableDataShow.push({});
        this.tableDataShow.push(...this.tableData);
        // this.tableDataShow.push({});
      }

      //去重
      this.removeDuplicates();
      this.filterDataTo();
      setTimeout(() => {
        // 触发鼠标事件，使其自动轮循播放
        this.$refs.seamlessScroll._move();
      }, 2000);
    },
    tableDataShow() {
      //去重
      this.removeDuplicates();
      this.filterDataTo();
    }
  }
};